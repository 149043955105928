@import '../../sass/variables';

.wrapper {
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  .iconWrapper {
    width: 45px;
    height: 45px;
    margin: 0 auto;

    &.md {
      margin-bottom: 20px;
    }

    &.sm {
      margin-bottom: 5px;
    }
  }
}

.iconWrapper {
  flex-shrink: 0;
}

.title {
  display: block;
  margin-bottom: 10px;
  text-align: center;
}

// Color
.light {
  color: $colorWhite;
}

.dark {
  color: $colorGray900;
}

@include media-breakpoint-up(md) {
  .wrapper {
    .iconWrapper {
      width: 45px;
      height: 45px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .wrapper {
    .iconWrapper {
      margin-left: 0;
    }
  }

  .title {
    text-align: left;
  }

  .description {
    text-align: left;
  }
}
