@import '../../../../sass/variables';

.wrapper {
  color: $colorGray500;
  border-top: 1px solid $colorGray200;
  transition: border $transition;

  &:first-child {
    border: none;

    .question {
      padding-top: 0;
    }

    .iconWrapper {
      top: 5px;
    }
  }
}

.isVisible {
  padding-bottom: 30px;

  .iconWrapper {
    transform: rotate(180deg);
  }

  .answer {
    height: auto;
    margin-left: 0;
  }

  .answerMargin {
    margin-bottom: 20px;
  }
}

.question {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px 45px 20px 0;
  font-size: 1rem;
  font-weight: $weight-medium;
  color: $colorGray900;
  text-align: left;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  transition: color $transition;

  &:focus-visible {
    outline: 1px solid $colorBlue300;
  }
}

.large {
  max-width: 600px;
}

.answer {
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;
}

.icon {
  display: block;
  width: 16px;
  height: 16px;
  fill: $colorBlue300;
}

.iconWrapper {
  position: absolute;
  top: 35px;
  right: 20px;
  transition: transform $transition;
}

@include media-breakpoint-up(sm) {
  .question {
    padding: 30px 45px 30px 20px;
    font-size: 1.125rem;
    line-height: 1.6;
  }

  .answer {
    padding: 0 20px;
  }
}
