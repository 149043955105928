@import '../../../sass/variables';

.row {
  flex-direction: column-reverse;
}

.link {
  color: inherit !important;
  text-decoration: underline;
}

@include media-breakpoint-up(md) {
  .row {
    flex-direction: row;

    &.isReversed {
      flex-direction: row-reverse;
    }
  }

  .imageWrapper {
    display: flex;
    justify-content: flex-end;
  }
}
