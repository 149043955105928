@import '../../sass/variables';

.title {
  display: block;
  font-size: 0.875rem;
  font-weight: $weight-medium;
  text-transform: uppercase;
  letter-spacing: 0.44px;
}

// Color
.blue {
  color: $colorBlue300;
}

.gray {
  color: $colorGray900;
}

.lightGray {
  color: $colorGray400;
}

.white {
  color: $colorWhite;
}

// Align
.left {
  text-align: left;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}
