@import '../../sass/variables';

.faqWrapper {
  max-width: 770px;
  margin: 0 auto;
}

.faq {
  margin-bottom: 10px;
  border-bottom: 1px solid $colorGray200;
}

@include media-breakpoint-up(sm) {
  .faq {
    margin-bottom: 30px;
  }
}
